import React from "react";

import { Helmet } from "react-helmet";

import { Dispatch } from "redux";
import { MouseEvent } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { navigate } from "gatsby";
import { useStaticQuery } from "gatsby";

import { batch } from "react-redux";
import { createRef } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";

import Box from "@js/component/Box";
import Button from "@js/component/Button";
import ChevronDownIcon from "@js/component/icon/ChevronDownIcon";
import Heading from "@js/component/Heading";
import HourglassHalfIcon from "@js/component/icon/HourglassHalfIcon";
import InformationForm from "@js/component/InformationForm";
import InputRadio from "@js/component/InputRadio";
import Main from "@js/component/Main";
import PriceTier from "@js/component/PriceTier";
import Select from "@js/component/Select";
import Slider from "@js/component/Slider";
import Stars from "@js/component/Stars";

import StoreState from "@js/store/StoreState";

import useDebounce from "@js/hook/useDebounce";

import {
    setArea,
    setAreaCode,
    setAreaCodeBag,
    setPromotion,
    setAreaManual, setHasCompleted, setHasVisited
} from "@js/actionCreators/informationActionCreators";
import { setBuildingType } from "@js/actionCreators/informationActionCreators";
import { setDeliveryTime } from "@js/actionCreators/filterActionCreators";
import { setMethodology } from "@js/actionCreators/filterActionCreators";
import { setOrderBy } from "@js/actionCreators/filterActionCreators";
import { setPriceMax } from "@js/actionCreators/filterActionCreators";
import { setPriceMin } from "@js/actionCreators/filterActionCreators";
import { setPriceTierMax } from "@js/actionCreators/filterActionCreators";
import { setPriceTierMin } from "@js/actionCreators/filterActionCreators";
import { setReviewAverage } from "@js/actionCreators/filterActionCreators";
import { setZipCode} from "@js/actionCreators/informationActionCreators";
import { setHouseNumber } from "@js/actionCreators/informationActionCreators";
import { setHouseNumberAddition } from "@js/actionCreators/informationActionCreators";

import * as classNames from "@css/component/template/Search.module.scss";
import InfoBalloon from "@js/component/InfoBalloon";
import {shuffle} from "lodash";
import PropositionBanner from "@js/component/icon/PropositionBanner";

/**
 * PriceTier
 */
type PriceTier = {
    advisor: {
        badge: string;
        companyImage: {
            originalName: string;
            url: string;
        };
        companyName: string;
        deliveryTime: number;
        full: boolean;
        id: number;
        reviewAveragePercentage: number;
        reviewTotalCount: number;
        reviews: {
            advisor: number;
            recentReviews: {
                advisor: number;
                billingCity: string;
                billingHouseNumber: number;
                billingHouseNumberSuffix: string;
                billingStreet: string;
                billingZipcode: string;
                buildingOwnerAffix: string;
                buildingOwnerCompanyName: string;
                buildingOwnerInitials: string;
                buildingOwnerName: string;
                buildingOwnerTitle: string;
                createdAt: string;
                deliveryTime: number;
                review: number;
                reviewMessage: string;
                status: string;
                url: string;
            }[];
            scores: {
                1: number;
                2: number;
                3: number;
                4: number;
                5: number;
            };
            stats: {
                averageScore: number;
                totalReviews: number;
                wilsonScore: number;
            };
        }[];
        url: string;
    };
    hash: string;
    id: number;
    methodology: string;
    buildingTypeCategory: string;
    price: number;
    requiresQuote: boolean;
    promotion?: {
        id: number;
        label: string;
        logo: {
            src?: string;
            alt?: string;
        };
        uri: string;
    };
};

/**
 * @type FilterData
 */
type FilterData = {
    filter: {
        options: {
            buildingType: {
                label: string;
                value: string;
                category: string;
                areas: {
                    label: string;
                    value: string;
                    quote: boolean;
                }[];
            }[];
            deliveryTime: {
                label: string;
                value: string;
            }[];
            methodology: {
                label: string;
                value: string;
            }[];
        };
    };
};

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    allFile: {
        edges: {
            node: {
                publicURL: string;
            }
        }[]
    };
};


/**
 * @type AdvertorialData
 */
type AdvertorialData = {
    wordpress: {
        other: {
            advertorials: {
                advertorial: {
                   advertorialImage: {
                       sourceUrl: string;

                   }
                   advertorialLocation: string;
                   advertorialUrl: string;
                }[]
            }
        }
    };
};

/**
 * @type Data
 */
type Data = FilterData & LogoPlaceholderData & AdvertorialData;

/**
 * @type MetaProps
 */
type MetaProps = {
    pageContext: {
        metaTitle: string;
        metaDescription: string;
        metaKeywords: string;
        metaUrl: string;
        title_h1: string;
    }
}

/**
 * @const Search
 */
const Search = (props: MetaProps) => {
    const {
        pageContext
    } = props;

    const dispatch = useDispatch();

    const debounce = useDebounce();
    const formRef = createRef<HTMLFormElement>();

    const data: Data = useStaticQuery(graphql`
        query {
            filter {
                options {
                    buildingType {
                        label
                        value
                        category
                        areas {
                            label
                            value
                            quote
                        }
                    }
                    deliveryTime {
                        label
                        value
                    }
                    methodology {
                        label
                        value
                    }
                }
            }
            allFile(filter: {relativePath: {eq: "placeholder.png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            wordpress {
                other {
                    advertorials {
                        advertorial {
                            advertorialUrl
                            advertorialLocation
                            advertorialImage {
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    `);

    const advisorUrl = useSelector((storeState: StoreState) => storeState.information.advisorUrl);
    const hasCompleted = useSelector((storeState: StoreState) => storeState.information.hasCompleted);

    const [isBlockOneOpen, setIsBlockOneOpen] = useState(false);
    const [isEditingInformation, setIsEditingInformation] = useState(false);
    const [isFetchingPriceTiers, setIsFetchingPriceTiers] = useState(true);

    const [isFetchingAvailablePriceTiers, setIsFetchingAvailablePriceTiers] = useState(true);
    const [isFetchingUnavailablePriceTiers, setIsFetchingUnavailablePriceTiers] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [tempBuildingType, setTempBuildingType] = useState("");
    const [tempZipCode, setTempZipCode] = useState("");
    const [tempHouseNumber, setTempHouseNumber] = useState("");
    const [tempHouseNumberAddition, setTempHouseNumberAddition] = useState("");
    const buildingTypeGroup = useSelector((storeState: StoreState) => storeState.information.buildingTypeGroup);

    const [availablePriceTiers, setAvailablePriceTiers] = useState<PriceTier[]>([]);
    const [unavailablePriceTiers, setUnavailablePriceTiers] = useState<PriceTier[]>([]);
    const [appendPriceTiers, setAppendPriceTiers] = useState(false);
    const [nextAvailablePriceTiers, setNextAvailablePriceTiers] = useState<PriceTier[]>([]);
    const [nextUnavailablePriceTiers, setNextUnavailablePriceTiers] = useState<PriceTier[]>([]);
    const [canShowMoreAvailable, setCanShowMoreAvailable] = useState(false);
    const [canShowMoreUnavailable, setCanShowMoreUnavailable] = useState(false);
    const [nextAvailablePage, setNextAvailablePage] = useState(2);
    const [nextUnavailablePage, setNextUnavailablePage] = useState(2);

    const deliveryTime = useSelector((storeState: StoreState) => storeState.filter.deliveryTime);
    const methodology = useSelector((storeState: StoreState) => storeState.filter.methodology);
    const orderBy = useSelector((storeState: StoreState) => storeState.filter.orderBy);
    const priceMax = useSelector((storeState: StoreState) => storeState.filter.priceMax);
    const priceMin = useSelector((storeState: StoreState) => storeState.filter.priceMin);
    const priceTierMax = useSelector((storeState: StoreState) => storeState.filter.priceTierMax);
    const priceTierMin = useSelector((storeState: StoreState) => storeState.filter.priceTierMin);
    const reviewAverage = useSelector((storeState: StoreState) => storeState.filter.reviewAverage);

    const areaCode = useSelector((storeState: StoreState) => storeState.information.areaCode);
    const buildingType = useSelector((storeState: StoreState) => storeState.information.buildingType);
    const zipCode = useSelector((storeState: StoreState) => storeState.information.zipCode);
    const houseNumber = useSelector((storeState: StoreState) => storeState.information.houseNumber);
    const houseNumberAddition = useSelector((storeState: StoreState) => storeState.information.houseNumberAddition);
    const areaManual = useSelector((storeState: StoreState) => storeState.information.areaManual);

    const [isInvalidBag, setIsInvalidBag] = useState(false);
    const [averageMarketPrice, setAverageMarketPrice] = useState("");

    const [onShowDeliveryTimeBalloon, setOnShowDeliveryTimeBalloon] = useState(false);
    const [onShowAreaBalloon, setOnShowAreaBalloon] = useState(false);
    const [onShowPriceBalloon, setOnShowPriceBalloon] = useState(false);
    const [onShowReviewBalloon, setOnShowReviewBalloon] = useState(false);
    const [onShowMethodologyBalloon, setOnShowMethodologyBalloon] = useState(false);

    const isReadyForFiltering =
        zipCode &&
        houseNumber &&
        buildingType;

    const storedBuildingTypeLabel = data.filter.options.buildingType.find(
        (option) => option.value == buildingType
    )?.label;

    const advertorialDesktop = data.wordpress.other.advertorials.advertorial?.find(
        (advertorial) => advertorial.advertorialLocation === 'search'
    );

    const advertorialMobile = data.wordpress.other.advertorials.advertorial?.find(
        (advertorial) => advertorial.advertorialLocation === 'mobile'
    );

    const fetchArea = async () => {
        if (tempZipCode && tempHouseNumber) {

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/bag/${ buildingTypeGroup }/${ tempZipCode }/${ tempHouseNumber }/${ tempHouseNumberAddition }`);

            const data = await response.json();

            if (!(data.status == "success")) {
                setIsInvalidBag(true);

                if (!areaManual) {
                    dispatch(setArea(''));
                    dispatch(setAreaCode(''));
                    dispatch(setAreaCodeBag(''));
                }

                return;
            }

            setIsInvalidBag(false);

            if (buildingTypeGroup === 'private' || (buildingTypeGroup === 'business' && !areaManual)) {
                dispatch(setArea(data.result.area));
                dispatch(setAreaCode(data.result.areaCode));
                dispatch(setAreaCodeBag(data.result.areaCode));

            }
        }
    };

    const onUpdateButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            if(buildingTypeGroup === 'business') {
                dispatch(setBuildingType(buildingTypeGroup[0].toUpperCase() + buildingTypeGroup.slice(1)));
            } else {
                dispatch(setBuildingType(tempBuildingType));
            }

            dispatch(setZipCode(tempZipCode));
            dispatch(setHouseNumber(tempHouseNumber));
            dispatch(setHouseNumberAddition(tempHouseNumberAddition));

            void fetchArea();

            setIsEditingInformation(false);
        }

        search();
    };

    const onClearButtonClick = () => {
        dispatch(setReviewAverage(""));
        dispatch(setDeliveryTime(""));
        dispatch(setMethodology("basic"));
        dispatch(setPriceMax(priceTierMax));
        dispatch(setPriceMin(priceTierMin));

        search();
    };

    const searchCTA = [
        {
            type: "averagePrice",
            title: "De gemiddelde marktprijs voor jouw situatie is:",
            text: "De gemiddelde marktprijs bepalen we door tarieven voor jouw situatie - op basis van: het postcodegebied, het type gebouw, de oppervlakte van het gebouw en de opnamemethodiek - op te halen uit onze eigen database en een ruim aantal externe kanalen. Waaruit we vervolgens het gemiddelde berekenen.",
        },
        {
            type: "random",
            title: "Altijd vergelijken van totaalprijzen!",
            text: "De getoonde tarieven zijn totaalprijzen waarvoor je jouw energielabel ontvangt. Hier komen geen kosten meer overheen.",
            price: ""
        }
    ];

    const search = (showLoader = true, append = false, currentPage = 1) => {
         if(!areaCode) {
             return;
         }

        if (showLoader) {
            setIsFetchingPriceTiers(true);
        }

        dispatch((() => async (dispatch: Dispatch, getState: () => StoreState) => {
            setIsFetchingAvailablePriceTiers(true);

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/search`, {
                body: JSON.stringify({
                    filters: {
                        area: getState().information.areaCode || undefined,
                        buildingType: getState().information.buildingType || undefined,
                        buildingTypeCategory: getState().information.buildingTypeGroup || undefined,
                        deliveryTime: getState().filter.deliveryTime || undefined,
                        fullAdvisors: false,
                        methodology: getState().filter.methodology || undefined,
                        priceMax: getState().filter.priceMax || undefined,
                        priceMin: getState().filter.priceMin || undefined,
                        reviewAverage: getState().filter.reviewAverage || undefined,
                        targetZipCode: getState().information.zipCode || undefined
                    },
                    orderBy: getState().filter.orderBy,
                    page: currentPage
                }),
                method: "POST"
            });

            const data = await response.json();

            setIsFetchingAvailablePriceTiers(false);

            if (append) {
                setAvailablePriceTiers([ ...availablePriceTiers, ...data.result ]);

                if(data.result) {
                    setNextAvailablePriceTiers(data.result);
                    setAppendPriceTiers(true);
                }
            }
            else {
                setAvailablePriceTiers(data.result);
                if(data.result) {
                    void fetchAverageMarketPrice(data.result[0]);
                }
            }

            if (showLoader) {
                setIsFetchingPriceTiers(false);
            }

            if (data.nextPage) {
                setCanShowMoreAvailable(true);
            }
            else {
                setCanShowMoreAvailable(false);
            }

            setNextAvailablePage(data.page + 1);

            dispatch(setPriceTierMax(parseInt(data.priceTierMax)));
            dispatch(setPriceTierMin(parseInt(data.priceTierMin)));

            if (priceMax == 0) {
                dispatch(setPriceMax(parseInt(data.priceTierMax)));
            }
        })());

        dispatch((() => async (dispatch: Dispatch, getState: () => StoreState) => {
            setIsFetchingUnavailablePriceTiers(true);

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/search`, {
                body: JSON.stringify({
                    filters: {
                        area: getState().information.areaCode || undefined,
                        buildingType: getState().information.buildingType || undefined,
                        buildingTypeCategory: getState().information.buildingTypeGroup || undefined,
                        deliveryTime: getState().filter.deliveryTime || undefined,
                        fullAdvisors: true,
                        methodology: getState().filter.methodology || undefined,
                        priceMax: getState().filter.priceMax || undefined,
                        priceMin: getState().filter.priceMin || undefined,
                        reviewAverage: getState().filter.reviewAverage || undefined,
                        targetZipCode: getState().information.zipCode || undefined
                    },
                    orderBy: getState().filter.orderBy,
                    page: currentPage
                }),
                method: "POST"
            });

            const data = await response.json();

            setIsFetchingUnavailablePriceTiers(false);

            if (append) {
                setUnavailablePriceTiers([ ...unavailablePriceTiers, ...data.result ]);

                if (data.result) {
                    setNextUnavailablePriceTiers(data.result);
                    setAppendPriceTiers(true);
                }
            }
            else {
                setUnavailablePriceTiers(data.result);
            }

            if (showLoader) {
                setIsFetchingPriceTiers(false);
            }

            if (data.nextPage) {
                setCanShowMoreUnavailable(true);
            }
            else {
                setCanShowMoreUnavailable(false);
            }

            setNextUnavailablePage(data.page + 1);

            dispatch(setPriceTierMax(parseInt(data.priceTierMax)));
            dispatch(setPriceTierMin(parseInt(data.priceTierMin)));

            if (priceMax == 0) {
                dispatch(setPriceMax(parseInt(data.priceTierMax)));
            }
        })());
    };

    const fetchAverageMarketPrice = async (advisor) => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/average-market-price/${ advisor.hash }/${ zipCode }`, {
            method: "GET"
        });

        const data = await response.json();

        if(data) {
            setAverageMarketPrice(data.price.toString());
        }
    }

    const FormatAverageMarketPriceSuffix = (suffix) => {
        if (!suffix) {
            return `00`;
        }

        if (suffix.length === 1) {
            return `${ suffix }0`;
        }

        return suffix;
    }

    useEffect(() => {
        if (!(isFetchingAvailablePriceTiers) && !(isFetchingUnavailablePriceTiers)) {
            const pushPriceTierData = appendPriceTiers
                ? [...nextAvailablePriceTiers, ...nextUnavailablePriceTiers]
                : [...availablePriceTiers, ...unavailablePriceTiers];

            const dataLayerData = {
                "event": "push",
                "priceTiers": pushPriceTierData.map((priceTier) => ({
                    "companyName": priceTier.advisor.companyName,
                    "companyID": priceTier.advisor.id,
                    "priceType": priceTier.price ? "price" : "offer",
                    "deliveryDays": priceTier.advisor.deliveryTime,
                    "availability": !(priceTier.advisor.full),
                    "method": priceTier.methodology,
                    "visitorSurface": areaCode,
                    "reviewScore": priceTier.advisor.reviewAveragePercentage,
                    "reviewAmount": priceTier.advisor.reviewTotalCount,
                    "price": priceTier.price ? priceTier.price : ""
                }))
            };

            dataLayerData.priceTiers.forEach((priceTier, index) => {
                if(typeof availablePriceTiers[index] === 'undefined') {
                    return;
                }

                if (appendPriceTiers) {
                    if (Object.prototype.hasOwnProperty.call(nextAvailablePriceTiers[index], 'price')) {
                        dataLayerData.priceTiers[index].price = nextAvailablePriceTiers[index].price;
                    }
                } else {
                    if (Object.prototype.hasOwnProperty.call(availablePriceTiers[index], 'price')) {
                        dataLayerData.priceTiers[index].price = availablePriceTiers[index].price;
                    }
                }
            });

            typeof window == "object" && window.gtag && window.gtag("set", dataLayerData);
        }
    }, [availablePriceTiers, unavailablePriceTiers, nextAvailablePriceTiers, nextUnavailablePriceTiers]);

    useEffect(() => {
        setTempBuildingType(buildingType);
        setTempZipCode(zipCode);
        setTempHouseNumber(houseNumber);
        setTempHouseNumberAddition(houseNumberAddition);
    }, [buildingTypeGroup]);

    useEffect(() => {
        dispatch(setReviewAverage(""));
        dispatch(setDeliveryTime(""));
        dispatch(setMethodology("basic"));
        dispatch(setPriceMax(priceTierMax));
        dispatch(setPriceMin(priceTierMin));

        debounce(() => {
            void fetchArea();

            if(!tempZipCode || !tempHouseNumber) {
                setIsButtonDisabled(true);
                dispatch(setAreaManual(false));
            } else {
                setIsButtonDisabled(false);
            }
        });

    }, [tempZipCode, tempHouseNumber, tempHouseNumberAddition, buildingTypeGroup]);

    useEffect(() => {
        search();

        dispatch(setHasVisited(true));

        if (!hasCompleted && !advisorUrl) {
            dispatch(setHasCompleted(true));
        }
    }, []);

    return (
        <Main>
            <Helmet>
                <title>{ pageContext.metaTitle ? pageContext.metaTitle : "Vergelijk energielabel-adviseurs" }</title>
                { pageContext.metaDescription && <meta content={ pageContext.metaDescription } name="description" /> }
                { pageContext.metaDescription && <meta content={ pageContext.metaDescription } property="og:description" /> }
                { pageContext.metaTitle && <meta content={ pageContext.metaTitle } property="og:title" /> }
                { pageContext.metaKeywords && <meta content={ pageContext.metaKeywords } property="og:keywords" /> }
                { pageContext.metaUrl && <meta content={ pageContext.metaUrl } property="og:url" /> }
                <meta content="woninglabel.nl" property="og:site_name" />
                <meta content="vergelijker" property="og:type" />
                <meta content={ process.env.SITE_URL + data.allFile.edges[0].node.publicURL } property="og:image" />
            </Helmet>
            <div className={ classNames.container }>
                <section className={ classNames.advisors }>
                    <header className={ classNames.header }>
                        <Heading className={ classNames.heading } element="h1" variant="large">
                            { pageContext.title_h1 ? pageContext.title_h1 : 'Onze energielabel-adviseurs voor jou geselecteerd' }
                        </Heading>
                        <Heading className={ classNames.subHeading } element="h2" variant="small">
                            Hieronder vind je alle energielabel-adviseurs die werkzaam zijn bij jou in de buurt!
                        </Heading>
                    </header>
                    <Button className={ classNames.button } onClick={ () => setIsBlockOneOpen(true) } type="button">
                        Mijn gegevens en filter
                    </Button>
                    <div className={ classNames.blocks }>
                        { advertorialDesktop &&
                            <a target="_blank" rel="noreferrer" href={ advertorialDesktop.advertorialUrl } className={ classNames.advertorial }>
                                <PropositionBanner banner={ advertorialDesktop.advertorialImage.sourceUrl } className={ classNames.image } />
                            </a>
                        }
                        <div className={ clsx(classNames.blockOne, {
                            [classNames.open]: isBlockOneOpen
                        }) }>
                            <Box className={ classNames.box }>
                                <Box className={ classNames.buttonBox }>
                                    <Button
                                        className={ classNames.button }
                                        onClick={ (event) => {
                                            onUpdateButtonClick(event);
                                            setIsBlockOneOpen(false);
                                        } }
                                        type="button"
                                    >
                                        Filter toepassen en naar resultaten
                                    </Button>
                                </Box>
                                <section className={ classNames.information }>
                                    <header className={ classNames.header }>
                                        <Heading className={ classNames.heading } element="h2" variant="small">
                                            Jouw gegevens
                                        </Heading>
                                        { (!(isEditingInformation) && isReadyForFiltering) &&
                                        <Button className={ classNames.button } onClick={
                                            () => setIsEditingInformation(true)
                                        } type="button">
                                            <span className={ classNames.text }>Wijzig</span>
                                            <ChevronDownIcon className={ classNames.icon } />
                                        </Button>
                                        }
                                    </header>
                                    { (isEditingInformation || !(isReadyForFiltering)) &&
                                    <InformationForm
                                        buildingTypeValue={ tempBuildingType }
                                        className={ classNames.informationForm }
                                        formRef={ formRef }
                                        isButtonDisabled={ !areaCode || !buildingType || isButtonDisabled }
                                        onBuildingTypeChange={ (event) => {
                                            setTempBuildingType(event.currentTarget.value);
                                            dispatch(setBuildingType(event.currentTarget.value));
                                        }}
                                        onButtonClick={ onUpdateButtonClick }
                                        onZipCodeChange={ (event) => {
                                            setTempZipCode(event.currentTarget.value.toUpperCase());
                                            areaManual && dispatch(setAreaManual(false));
                                        }}
                                        onHouseNumberChange={ (event) => {
                                            setTempHouseNumber(event.currentTarget.value);
                                            areaManual && dispatch(setAreaManual(false));
                                        }}
                                        onHouseNumberAdditionChange={ (event) => {
                                            setTempHouseNumberAddition(event.currentTarget.value.toUpperCase());
                                            areaManual && dispatch(setAreaManual(false));
                                        }}
                                        variant="sidebar"
                                        zipCodeValue={ tempZipCode }
                                        houseNumberValue={ tempHouseNumber }
                                        houseNumberAdditionValue={ tempHouseNumberAddition }
                                        isInvalidBag={ isInvalidBag }
                                    />
                                    }
                                    { (!(isEditingInformation) && isReadyForFiltering) &&
                                    <div className={ classNames.values }>
                                        { (zipCode) &&
                                        <>
                                            <div className={ classNames.label }>Postcode:</div>
                                            <div className={ classNames.value }>
                                                { zipCode }
                                            </div>
                                        </>
                                        }
                                        { (houseNumber || houseNumberAddition) &&
                                        <>
                                            <div className={ classNames.label }>Huisnummer:</div>
                                            <div className={ classNames.value }>
                                                { houseNumber } { houseNumberAddition }
                                            </div>
                                        </>
                                        }

                                        <div className={ classNames.label }>Type gebouw:</div>
                                        <div className={ classNames.value }>
                                            { buildingTypeGroup == 'private' && <>Woning<br /></> }
                                            { storedBuildingTypeLabel }
                                        </div>

                                        { areaCode &&
                                        <>
                                            <div className={ classNames.label }>Oppervlakte:</div>
                                            <div className={ classNames.value }>
                                                { data.filter.options.buildingType
                                                    .filter(type => type.category === buildingTypeGroup && type.value === buildingType)
                                                    .map((areaOptions) => (
                                                        areaOptions.areas.filter(option => option.value == areaCode).map((area) => (
                                                            <span key={ area.value }>
                                                                { area.label } m²
                                                            </span>
                                                        ))
                                                    ))
                                                }

                                                { !areaManual &&
                                                    <span
                                                        className={classNames.balloonLabel}
                                                        onMouseEnter={() => {
                                                            setOnShowAreaBalloon(true)
                                                        }}
                                                        onMouseLeave={() => {
                                                            setOnShowAreaBalloon(false)
                                                        }}
                                                    >?
                                                        {onShowAreaBalloon &&
                                                        <InfoBalloon
                                                            className={classNames.area}
                                                        >
                                                            De vergelijking wordt weergegeven op basis van de
                                                            gebouwoppervlakte
                                                            die bekend is bij de overheid. Klopt dit niet?
                                                            Neem contact met ons op middels de contactgegevens onderaan de
                                                            pagina.
                                                        </InfoBalloon>
                                                        }
                                                    </span>
                                                }
                                            </div>
                                        </>
                                        }
                                    </div>
                                    }
                                </section>
                                { isReadyForFiltering &&
                                <section className={ classNames.filter }>
                                    <header className={ classNames.header }>
                                        <Heading className={ classNames.heading } element="h2" variant="small">Filter</Heading>
                                        <Button
                                            className={ classNames.button }
                                            onClick={ onClearButtonClick }
                                            type="button"
                                        >
                                            Herstel alles
                                        </Button>
                                    </header>
                                    <form className={ classNames.form }>
                                        <fieldset className={ classNames.fieldset }>
                                            <legend className={ classNames.legend }>
                                                Minimale beoordeling
                                                <span
                                                    className={ classNames.balloonLabel }
                                                    onMouseEnter={ () => {
                                                        setOnShowReviewBalloon(true)
                                                    }}
                                                    onMouseLeave={ () => {
                                                        setOnShowReviewBalloon(false)
                                                    }}
                                                >?
                                                    { onShowReviewBalloon &&
                                                        <InfoBalloon>
                                                            Op basis van klantervaringen die via Woninglabel.nl een aanvraag bij de energielabel-adviseur hebben gedaan.
                                                        </InfoBalloon>
                                                    }
                                                    </span>
                                            </legend>
                                            <InputRadio
                                                checked={ reviewAverage == "5" }
                                                labelClassName={ classNames.inputRadioLabel }
                                                name="review_average"
                                                onClick={
                                                    (event) => {
                                                        dispatch(setReviewAverage(reviewAverage == "5" ? "" : event.currentTarget.value));
                                                        debounce(() => {
                                                            search();
                                                        });
                                                    }
                                                }
                                                onChange={ () =>
                                                    debounce(() => {
                                                        search();
                                                    })
                                                }
                                                value="5"
                                            >
                                                <Stars
                                                    backgroundColor="#E5E5E5"
                                                    foregroundColor="#FFC402"
                                                    foregroundPercentage={ 100 }
                                                />
                                            </InputRadio>
                                            <InputRadio
                                                checked={ reviewAverage == "4" }
                                                labelClassName={ classNames.inputRadioLabel }
                                                name="review_average"
                                                onClick={
                                                    (event) => {
                                                        dispatch(setReviewAverage(reviewAverage == "4" ? "" : event.currentTarget.value));
                                                        debounce(() => {
                                                            search();
                                                        });
                                                    }
                                                }
                                                onChange={ () =>
                                                    debounce(() => {
                                                        search();
                                                    })
                                                }
                                                value="4"
                                            >
                                                <Stars
                                                    backgroundColor="#E5E5E5"
                                                    foregroundColor="#FFC402"
                                                    foregroundPercentage={ 80 }
                                                />
                                            </InputRadio>
                                            <InputRadio
                                                checked={ reviewAverage == "3" }
                                                labelClassName={ classNames.inputRadioLabel }
                                                name="review_average"
                                                onClick={
                                                    (event) => {
                                                        dispatch(setReviewAverage(reviewAverage == "3" ? "" : event.currentTarget.value));
                                                        debounce(() => {
                                                            search();
                                                        });
                                                    }
                                                }
                                                onChange={ () =>
                                                    debounce(() => {
                                                        search();
                                                    })
                                                }
                                                value="3"
                                            >
                                                <Stars
                                                    backgroundColor="#E5E5E5"
                                                    foregroundColor="#FFC402"
                                                    foregroundPercentage={ 60 }
                                                />
                                            </InputRadio>
                                            <InputRadio
                                                checked={ reviewAverage == "2" }
                                                labelClassName={ classNames.inputRadioLabel }
                                                name="review_average"
                                                onClick={
                                                    (event) => {
                                                        dispatch(setReviewAverage(reviewAverage == "2" ? "" : event.currentTarget.value));
                                                        debounce(() => {
                                                            search();
                                                        });
                                                    }
                                                }
                                                onChange={ () =>
                                                    debounce(() => {
                                                        search();
                                                    })
                                                }
                                                value="2"
                                            >
                                                <Stars
                                                    backgroundColor="#E5E5E5"
                                                    foregroundColor="#FFC402"
                                                    foregroundPercentage={ 40 }
                                                />
                                            </InputRadio>
                                            <InputRadio
                                                checked={ reviewAverage == "1" }
                                                labelClassName={ classNames.inputRadioLabel }
                                                name="review_average"
                                                onClick={
                                                    (event) => {
                                                        dispatch(setReviewAverage(reviewAverage == "1" ? "" : event.currentTarget.value));
                                                        debounce(() => {
                                                            search();
                                                        });
                                                    }
                                                }
                                                onChange={ () =>
                                                    debounce(() => {
                                                        search();
                                                    })
                                                }
                                                value="1"
                                            >
                                                <Stars
                                                    backgroundColor="#E5E5E5"
                                                    foregroundColor="#FFC402"
                                                    foregroundPercentage={ 20 }
                                                />
                                            </InputRadio>
                                        </fieldset>
                                        <fieldset className={ classNames.fieldset }>
                                            <legend className={ classNames.legend }>
                                                Levertijd van energielabel
                                                <span
                                                    className={ classNames.balloonLabel }
                                                    onMouseEnter={ () => {
                                                        setOnShowDeliveryTimeBalloon(true)
                                                    } }
                                                    onMouseLeave={ () => {
                                                        setOnShowDeliveryTimeBalloon(false)
                                                    } }
                                                >?
                                                    { onShowDeliveryTimeBalloon &&
                                                    <InfoBalloon>
                                                        Tijd tussen aanvraag en ontvangen van het energielabel indien
                                                        de energielabel-adviseur tijdig de mogelijkheid krijgt om langs te komen voor een gebouwopname.
                                                    </InfoBalloon>
                                                    }
                                                </span>
                                            </legend>
                                            { data.filter.options.deliveryTime.map((option) => (
                                                <InputRadio
                                                    checked={ deliveryTime == option.value }
                                                    key={ option.value }
                                                    labelClassName={ classNames.inputRadioLabel }
                                                    name="delivery_time"
                                                    onClick={
                                                        (event) => {
                                                            dispatch(setDeliveryTime(deliveryTime == option.value ? "" : event.currentTarget.value));
                                                            debounce(() => {
                                                                search();
                                                            });
                                                        }
                                                    }
                                                    onChange={ () =>
                                                        debounce(() => {
                                                            search();
                                                        })
                                                    }
                                                    value={ option.value }
                                                >
                                                    { option.label }
                                                </InputRadio>
                                            )) }
                                        </fieldset>
                                        <fieldset className={ classNames.fieldset }>
                                            <legend className={ classNames.legend }>
                                                Prijs in euro&apos;s
                                                <span
                                                    className={ classNames.balloonLabel }
                                                    onMouseEnter={ () => {
                                                        setOnShowPriceBalloon(true)
                                                    } }
                                                    onMouseLeave={ () => {
                                                        setOnShowPriceBalloon(false)
                                                    } }
                                                >?
                                                    { onShowPriceBalloon &&
                                                        <InfoBalloon>
                                                            Dit is de totaalprijs waarvoor je jouw energielabel ontvangt. Hier komen geen kosten meer overheen.
                                                        </InfoBalloon>
                                                    }
                                                </span>
                                            </legend>
                                            <Slider
                                                className={ classNames.slider }
                                                max={ priceTierMax }
                                                min={ priceTierMin }
                                                onChange={ (value) => {
                                                    const castValue = value;

                                                    const priceMax = castValue[1];
                                                    const priceMin = castValue[0];

                                                    batch(() => {
                                                        dispatch(setPriceMax(priceMax));
                                                        dispatch(setPriceMin(priceMin));
                                                    });

                                                    debounce(() => {
                                                        search();
                                                    });
                                                }}
                                                value={ [priceMin, priceMax] }
                                            />
                                        </fieldset>
                                        <fieldset className={ classNames.fieldset }>
                                            <legend className={ classNames.legend }>
                                                Methodiek
                                                <span
                                                    className={ classNames.balloonLabel }
                                                    onMouseEnter={ () => {
                                                        setOnShowMethodologyBalloon(true)
                                                    } }
                                                    onMouseLeave={ () => {
                                                        setOnShowMethodologyBalloon(false)
                                                    } }
                                                >?
                                                    { onShowMethodologyBalloon &&
                                                    <InfoBalloon
                                                        className={ classNames.methodology }
                                                    >
                                                        Kies Basismethodiek bij een bestaand gebouw gebouwd voor 01-01-2021.
                                                        Kies Detailmethodiek bij een bestaand gebouw gebouwd na 01-01-2021, oplevering van nieuwbouw, renovatie naar nieuwbouwniveau of aanvraag van een omgevingsvergunning.
                                                    </InfoBalloon>
                                                    }
                                                </span>
                                            </legend>
                                            { data.filter.options.methodology.map((option) => (
                                                <InputRadio
                                                    checked={ methodology == option.value }
                                                    key={ option.value }
                                                    labelClassName={ classNames.inputRadioLabel }
                                                    name="methodology"
                                                    onChange={ (event) => {
                                                        dispatch(setMethodology(event.currentTarget.value));

                                                        debounce(() => {
                                                            search();
                                                        });
                                                    } }
                                                    value={ option.value }
                                                >
                                                    { option.label }
                                                </InputRadio>
                                            )) }
                                        </fieldset>
                                    </form>
                                </section>
                                }
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            { (isReadyForFiltering && !isEditingInformation) &&
                            <>
                                <Box className={ classNames.box } element="section">
                                    <header className={ classNames.header }>
                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                            Kies jouw energielabel-adviseur
                                        </Heading>
                                        <div className={ classNames.sort }>
                                            <div className={ classNames.label }>Sorteren op</div>
                                            <Select className={ classNames.select } onChange={ (event) => {
                                                dispatch(setOrderBy(event.currentTarget.value));

                                                debounce(() => {
                                                    search();
                                                });
                                            } } value={ orderBy }>
                                                <option value="reviewAverage">Beoordeling</option>
                                                <option value="deliveryTime">Levertijd</option>
                                                <option value="price">Prijs</option>
                                            </Select>
                                        </div>
                                    </header>
                                    { (isFetchingPriceTiers || !areaCode) &&
                                        <>
                                            <div className={ classNames.searching }>
                                                <span>We zoeken de beste energielabel-adviseurs voor jou!</span>
                                                <div className={ classNames.searchSpinner }>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    { !(isFetchingPriceTiers) && (areaCode) &&
                                    <>
                                        { availablePriceTiers.length > 0 &&
                                        <ul className={ classNames.list }>
                                            { availablePriceTiers.map((priceTier: PriceTier, index) => (
                                                <div key={ index }>
                                                    { index === 3 &&
                                                        <li className={ classNames.cta }>
                                                            { searchCTA.filter(cta => cta.type === "averagePrice").map((cta, index) => (
                                                                <div className={ classNames.ctaWrapper } key={ index }>
                                                                    <div className={ classNames.header }>
                                                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                                                            { cta.title }
                                                                        </Heading>
                                                                        <span className={ clsx(classNames.shimmerAnimate, { [classNames.price]: averageMarketPrice }) }>
                                                                            <span className={ classNames.integer }>{ averageMarketPrice ? `${ averageMarketPrice.split(".")[0] },` : "" }</span>
                                                                            <span className={ classNames.fraction }>
                                                                                { FormatAverageMarketPriceSuffix(averageMarketPrice.split(".")[1]) }
                                                                            </span>
                                                                            { averageMarketPrice &&
                                                                                <div className={ classNames.includingVat }>
                                                                                    totaal { buildingTypeGroup === 'private' ? 'incl.' : 'excl.' } btw
                                                                                </div>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <p className={ classNames.paragraph }>{ cta.text }</p>
                                                                </div>
                                                            ))}
                                                        </li>
                                                    }

                                                    {index === 5 && priceTier.promotion &&
                                                        <li className={ classNames.cta }>
                                                            <div className={ classNames.ctaWrapper }>
                                                                <a
                                                                    className={ classNames.proposition }
                                                                    href={ priceTier.promotion.uri }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <div className={ classNames.header }>
                                                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                                                            { priceTier.promotion.label }
                                                                        </Heading>
                                                                        <img className={ classNames.propositionImage }
                                                                             src={ priceTier.promotion.logo.src }
                                                                             alt={ priceTier.promotion.logo.alt ? priceTier.promotion.logo.alt : "proposition" }
                                                                        />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    }

                                                    { index === 5 && advertorialMobile &&
                                                        <a target="_blank" rel="noreferrer" href={ advertorialMobile.advertorialUrl } className={ classNames.advertorial }>
                                                            <PropositionBanner banner={ advertorialMobile.advertorialImage.sourceUrl } className={ classNames.banner } />
                                                        </a>
                                                    }

                                                    { index === 7 &&
                                                        <li className={ classNames.cta }>
                                                            { shuffle(searchCTA.filter(cta => cta.type === "random")).slice(0, 1).map((cta, index) => (
                                                                <div className={ classNames.ctaWrapper } key={ index }>
                                                                    <div className={ classNames.header }>
                                                                        <Heading className={ clsx(classNames.heading, classNames.random) } element="h2" variant="medium">
                                                                            { cta.title }
                                                                        </Heading>
                                                                    </div>
                                                                    <p className={ clsx(classNames.paragraph, classNames.random) }>{ cta.text }</p>
                                                                </div>
                                                            ))}
                                                        </li>
                                                    }

                                                    <li className={ classNames.listItem } key={ `i${priceTier.id}` }>
                                                        <PriceTier
                                                            advisor={ priceTier.advisor }
                                                            advisorId={ priceTier.advisor.id }
                                                            advisorUrl={ `/over-ons/energielabel-adviseurs/${ priceTier.advisor.url }` }
                                                            buttonProps={{
                                                                disabled: !(isReadyForFiltering)
                                                            }}
                                                            buttonText={ priceTier.price ? "Aanvragen" : "Offerte opvragen" }
                                                            companyName={ priceTier.advisor.companyName }
                                                            deliveryTime={ priceTier.advisor.deliveryTime }
                                                            image={ priceTier.advisor.companyImage.url }
                                                            onButtonClick={ () => {
                                                                dispatch(setPromotion(priceTier.promotion));
                                                                navigate(`/aanvragen/${ priceTier.hash }`);
                                                            } }
                                                            price={ priceTier.price?.toString() }
                                                            reviewAveragePercentage={
                                                                priceTier.advisor.reviewAveragePercentage
                                                            }
                                                            reviewTotalCount={ priceTier.advisor.reviewTotalCount }
                                                            buildingTypeCategory={ priceTier.buildingTypeCategory }
                                                            showTabs={ true }
                                                        />
                                                    </li>
                                                </div>
                                            )) }
                                        </ul>
                                        }
                                        { availablePriceTiers.length == 0 &&
                                          debounce(() => (
                                            <div className={ classNames.noResults }>
                                                Er zijn geen resultaten gevonden.
                                            </div>
                                          ))
                                        }
                                    </>
                                    }
                                </Box>
                                { !(isFetchingPriceTiers) && canShowMoreAvailable && availablePriceTiers.length > 0 &&
                                <Box className={ classNames.boxShowMore }>
                                    <Button className={ classNames.button } onClick={
                                        () => search(false, true, nextAvailablePage)
                                    }>
                                        Toon meer resultaten <ChevronDownIcon className={ classNames.icon } />
                                    </Button>
                                </Box>
                                }
                                { !(isFetchingPriceTiers) && unavailablePriceTiers.length > 0 &&
                                <Box className={ classNames.box }>
                                    <header className={ classNames.header }>
                                        <Heading className={ classNames.heading } variant="medium">
                                            Deze energielabel-adviseurs zijn vandaag niet beschikbaar
                                        </Heading>
                                    </header>
                                    <div className={ classNames.message }>
                                        <HourglassHalfIcon className={ classNames.icon } />
                                        <span>
                                                Onderstaande energielabel-adviseurs zijn al ingepland.
                                                Kies uit bovenstaande energielabel-adviseurs of probeer het morgen nog een keer.
                                            </span>
                                    </div>
                                    { unavailablePriceTiers.length > 0 &&
                                    <ul className={ classNames.list }>
                                        { unavailablePriceTiers.map((priceTier: PriceTier) => (
                                            <li className={ classNames.listItem } key={ priceTier.id }>
                                                <PriceTier
                                                    advisor={ priceTier.advisor }
                                                    advisorId={ priceTier.advisor.id }
                                                    advisorUrl={ `/over-ons/energielabel-adviseurs/${ priceTier.advisor.url }` }
                                                    buttonProps={{
                                                        className: classNames.button
                                                    }}
                                                    buttonText="Naar energielabel-adviseur"
                                                    companyName={ priceTier.advisor.companyName }
                                                    deliveryTime={ priceTier.advisor.deliveryTime }
                                                    image={ priceTier.advisor.companyImage.url }
                                                    onButtonClick={ () => {
                                                        navigate(`/over-ons/energielabel-adviseurs/${ priceTier.advisor.url }`) }
                                                    }
                                                    price={ null }
                                                    reviewAveragePercentage={
                                                        priceTier.advisor.reviewAveragePercentage
                                                    }
                                                    reviewTotalCount={ priceTier.advisor.reviewTotalCount }
                                                    showDeliveryTime={ false }
                                                    buildingTypeCategory={ priceTier.buildingTypeCategory }
                                                    promotion={ priceTier.promotion }
                                                    showTabs={ true }
                                                />
                                            </li>
                                        )) }
                                    </ul>
                                    }
                                </Box>
                                }
                                { !(isFetchingPriceTiers) && canShowMoreUnavailable && unavailablePriceTiers.length > 0 &&
                                <Box className={ classNames.boxShowMore }>
                                    <Button className={ classNames.button } onClick={
                                        () => search(false, true, nextUnavailablePage)
                                    }>
                                        Toon meer resultaten <ChevronDownIcon className={ classNames.icon } />
                                    </Button>
                                </Box>
                                }
                            </>
                            }
                            { (!(isReadyForFiltering) || isEditingInformation) &&
                            <Box className={ classNames.box } element="section">
                                <header className={ classNames.header }>
                                    <Heading className={ classNames.heading } element="h2" variant="medium">
                                        Kies jouw energielabel-adviseur
                                    </Heading>
                                </header>
                                <section className={ classNames.message }>
                                    Vul jouw gegevens in en je ziet meteen de voor jou beschikbare producten.
                                </section>
                            </Box>
                            }
                        </div>
                        { advertorialDesktop &&
                            <a target="_blank" rel="noreferrer" href={ advertorialDesktop.advertorialUrl } className={ classNames.advertorial }>
                                <PropositionBanner banner={ advertorialDesktop.advertorialImage.sourceUrl } className={ classNames.image }/>
                            </a>
                        }
                    </div>
                </section>
            </div>
        </Main>
    );
};

export default Search;
